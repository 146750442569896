import React, { useEffect } from "react";
import { useTranslation, Trans } from "@ca-dmv-radv/translation";
import { RadioGroup, RadioItem, Icon } from "@ca-dmv/core";
import { APPLICATION_TYPE, getOptedCategory, postOptedCategory, refreshDocumentUploads } from "@ca-dmv-radv/data";

export default function OptionalDocumentPrompt({ applicationType, proofOfDocumentCategory, setWillUpload, optedIn }) {
  const { t } = useTranslation();

  const message = optedIn === true
    ? t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-optionalYesMessage`,
        `Please provide a photo of your ${proofOfDocumentCategory.categoryName} by selecting one of the options below. The signature will be used on the new card once it has been uploaded and approved.`
      )
    : t(
        `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-optionalNoMessage`,
        `You have chosen to not upload a ${proofOfDocumentCategory.categoryName} document.`
      );
  const legendClass =applicationType === APPLICATION_TYPE.CEC
    ? "text--blue-dark-2 text--700 text--md"
    : "text--sm text--h5";

  const handleDescision = async (decision) => {
    if (applicationType !== APPLICATION_TYPE.CEC) await postOptedCategory(applicationType, proofOfDocumentCategory.categoryId, decision);
    setWillUpload(decision);
  }

  // Default to document upload option for CEC
  useEffect(() => {
    if (applicationType === APPLICATION_TYPE.CEC) {
      setWillUpload(true);
    }
  }, []);

  return (
    <>
      <RadioGroup
        legend={t(
          `${applicationType}-category${proofOfDocumentCategory.categoryId}-optional-prompt`, 
          `Would you like to upload ${proofOfDocumentCategory.categoryName} documents?`
        )}
        containerClass="mb-8"
        legendClass={legendClass}
      >
        <RadioItem
            containerClass="mb-8"
            label={t(
              `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-yes`, 
              "Yes"
            )}
            onChange={async () => await handleDescision(true)}
            required={false}
            checked={optedIn === true}
          />
          <RadioItem
            containerClass="mb-8"
            label={t(
              `${applicationType}-category${proofOfDocumentCategory.categoryId}-optionalUpload-no`, 
              "No"
            )}
            onChange={async () => await handleDescision(false)}
            required={false}
            checked={optedIn === false}
          />
      </RadioGroup>
      {optedIn !== null && applicationType !== APPLICATION_TYPE.CEC && (
        <div className="show--flex">
          <Icon icon="check" className="mr-8 mt-5 min-width--15" />
          <p className="text--sm text--blue-dark-2 mb-16">
            <Trans>
              {message}
            </Trans>
          </p>
        </div>
      )}
    </>
  );
}