import React, { useEffect, useRef, useState } from "react";
import { applyPolyfills, defineCustomElements } from "@microblink/blinkid-in-browser-sdk/ui/loader";
import { verifyEligibility } from "@ca-dmv-radv/data";
import { useNavigate } from "react-router-dom";
import { PROCESSING_PHOTOS_PROCESSING } from "@ca-dmv-radv/files";
import { Modal } from "@ca-dmv/modal";
import { useTranslation } from "@ca-dmv-radv/translation";
import { Button } from "@ca-dmv/core/dist/js/button";
import { validateDL } from "../../constants";

export default function BlinkDocumentScan({ blinkRef, recaptchaRef, setProcessingPhotos }) {
	const { t } = useTranslation();
	const [driversLicenseObj, setDriversLicenseObj] = useState(null);
	const [showError, setShowError] = useState(false);
	const [dataError, setDataError] = useState(false);
	const navigate = useNavigate();

	const formatDate = (month, day, year) => `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

	const handleOnScanSuccess = async (event) => {
		const barcodeData = event.detail.recognizer.barcode;
		const dlNumber = barcodeData.documentNumber;
		const lastName = barcodeData.lastName;
		if (barcodeData.dateOfBirth.originalString === "") {
			console.error("No date of birth");
			return setShowError(true);
		}
		const { month, day, year } = barcodeData.dateOfBirth;
		let obj = { dlNumber, lastName, month, day, year }
		console.log("Validating DL: " + JSON.stringify(obj));
		if (!validateDL(obj)) {
			console.error("DL object failed validation");
			setDataError(true);
			return setShowError(true);
		}
		const formattedDate = formatDate(month, day, year);
		if (!showError) {
			setDriversLicenseObj({
				dlNumber: dlNumber,
				lastName: lastName,
				dob: formattedDate,
			});
		}
	};

	const handleError = () => {
		setShowError(true);
	};

	useEffect(() => {
		if (driversLicenseObj) {
			setProcessingPhotos(PROCESSING_PHOTOS_PROCESSING);
			(async () => {
				const recaptchaToken = await recaptchaRef.current.executeAsync();
				recaptchaRef.current.reset();
	
				const payload = {
					...driversLicenseObj,
					recaptchaToken: recaptchaToken,
					source: "BlinkID"
				};
	
				await verifyEligibility(payload).then((response) => {
					navigate("/result", { state: { isEligible: response.success } });
				});
			})();
		}
	}, [driversLicenseObj]);

	useEffect(() => {
		applyPolyfills().then(() => {
      	defineCustomElements().then(() => {
				blinkRef.current.licenseKey = process.env.REACT_APP_BLINK_ID_LICENSE_KEY;
				blinkRef.current.recognizers = ["BlinkIdSingleSideRecognizer"];

				blinkRef.current.blinkIDVariant = "full";
				blinkRef.current.scanFromImage = false;
				blinkRef.current.showCameraFeedbackBarcodeMessage = true;
				blinkRef.current.allowHelpScreensFab = false;
				blinkRef.current.allowHelpScreensOnboarding = false;
				blinkRef.current.translations = {
					"action-message" : "Scan Document",
					// Camera defaults to instruction key for scanning front of document, but we want the user to scan the back
					"camera-feedback-scan-front": ["Scan the back of", "your Driver's License"]		
				}

        // Engine location depends on the actual location of WebAssembly resources
        blinkRef.current.engineLocation = window.location.origin + "/libs/blinkid/resources";
				blinkRef.current.workerLocation = window.location.origin + "/libs/blinkid/resources/BlinkIDWasmSDK.worker.min.js";
        // blinkRef.current.addEventListener("ready", (ev) => {
        //   console.log("ready", ev.detail);
        // });

        blinkRef.current.addEventListener("scanSuccess", (ev) => {
          console.log("scanSuccess", ev.detail);
					handleOnScanSuccess(ev);
        });

        blinkRef.current.addEventListener("scanError", (ev) => {
          console.log("scanError", ev.detail);
					handleError()
        });

        blinkRef.current.addEventListener("fatalError", (ev) => {
          console.log("fatalError", ev.detail);
					handleError()
        });
      });
    });
	}, []);
  
	return (
    <>
      {showError && (
        <Modal
          modalTitle={t(
            "cec-blinkId-error-header",
            "Error"
          )}
		  childrenContainerClass="bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"
          onClose={() => setShowError(false)}
        >
            <p>
              {dataError 
                ? t("cec-blinkId-error-invalidData", "There was an issue processing your data. Please scan your document again.") 
                : t("cec-blinkId-error-subheader","Something went wrong. Please try again.")
              }
            </p>
            <Button
              label={t("buttonOkLabel", "Ok")}
              onClick={() => {
                setShowError(false);
                setDataError(false);
              }}
              buttonClass="w--100 mb-12"
            />
        </Modal>
      )}
      <blinkid-in-browser ref={blinkRef}></blinkid-in-browser>
    </>
  );

}
