import { APPLICATION_TYPE, useIdentityDocument, useResidencyDocuments } from "@ca-dmv-radv/data";

export default function useTranslateDocumentName(document, applicationType) {
  const allDocuments = [];

  if (![APPLICATION_TYPE.VDL, APPLICATION_TYPE.CEC].includes(applicationType)) {
    const { identityDocuments } = useIdentityDocument();
    allDocuments.push(...identityDocuments);
  }

  if (applicationType === APPLICATION_TYPE.DV) {
    const { residencyDocuments } = useResidencyDocuments();
    allDocuments.push(...residencyDocuments);
  }
  const translatedDocument = allDocuments.find((doc) => {
    return doc.id === document.catDocId;
  });

  return translatedDocument ? translatedDocument.name : null;
}
