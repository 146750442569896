let captureFw = undefined;

//Converts blob to file
async function blobtoFile(blob, filename, type = "images/png") {
  const dataUrl = await blobToDataUrl(blob);
  return {
    dataUrl: dataUrl,
    name: filename,
    original: new File([blob], filename, { type }),
    type,
  };
}

//Converts blob to image dataUrl to display
const convertBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

async function blobToDataUrl(file) {
  const dataURL = await convertBase64(file);
  dataURL.replace(",", "---");
  return dataURL;
}

const convertResultToBlob = (res) => {
  const dataType = res.split(";base64,")[0].split(":")[1];
  const base64Result = res.split(";base64,")[1];

  const base64ByteCharacters = atob(base64Result);
  const base64ByteNumbers = new Array(base64ByteCharacters.length);
  for (let i = 0; i < base64ByteNumbers.length; i++) {
    base64ByteNumbers[i] = base64ByteCharacters.charCodeAt(i);
  }
  const base64ByteArray = new Uint8Array(base64ByteNumbers);

  return new Blob([base64ByteArray], { type: dataType });
}

const setSelfieSettings = (selfieSettings) => {
  selfieSettings.showOnlyOneFace = "Make sure only one face is on the screen.";
  selfieSettings.somethingWentWrong = "We encountered an issue. Please try again.";
  selfieSettings.error = "We encountered an issue. Please try again.";
  selfieSettings.versionNotSupported = "Browser version is not supported. Please try again.";
  selfieSettings.updateBrowser = "Update your browser version. Please try again.";
  selfieSettings.tryAgain = "We encountered an issue. Please try again.";
  selfieSettings.noCameraAvailable = "Unable to access the camera.";
  selfieSettings.checkCameraConnection = "Unable to access the camera.";
  selfieSettings.lookStraight = "Look straight while performing the capture.";
  selfieSettings.fitYourFace = "Move your face closer to the screen.";
  selfieSettings.moveCloser = "Move your face closer to the screen.";
  selfieSettings.moveAway = "Move your face farther from the screen.";
  selfieSettings.holdSteady = "Keep still while performing the capture.";
  selfieSettings.processing = "Processing";
  selfieSettings.noCameraPermission = "Unable to access the camera.";
};

const setDocumentSettings = (documentSettings) => {
  documentSettings.error = "We encountered an issue. Please try again.";
  documentSettings.somethingWentWrong = "We encountered an issue. Please try again.";
  documentSettings.tryAgain = "We encountered an issue. Please try again.";
  documentSettings.cameraUnavailable = "Unable to access the camera.";
  documentSettings.noCameraAvailable = "Unable to access the camera.";
  documentSettings.cameraConnection = "Unable to access the camera.";
  documentSettings.versionNotSupported = "Browser version is not supported. Please try again.";
  documentSettings.updateBrowser = "Update your browser version. Please try again.";
  documentSettings.processing = "Processing Image";
  documentSettings.detectingDocument = "Detecting document";
  documentSettings.placeDocumentIntoFrame = "Align the document within the frame.";
  documentSettings.noFocus = "Please focus your camera";
  documentSettings.moveCloser = "Move document closer.";
  documentSettings.glaresOnDocument = "Avoid glare on the document.";
  documentSettings.companyLogo = process.env.REACT_APP_AID_LOGO;
};

export function initializeAID() {
  if (document.getElementById("aid-script")) {
    return;
  }
  const script = document.createElement("script");
  script.src = "/libs/authentic-id/js/IDMetricsCaptureFramework.js";
  script.id = "aid-script"
  script.async = true;
  script.onload = () => {
    window.onCaptureFrameworkLoaded = function() {
      let devLicenseKey = undefined;
      if( process.env.REACT_APP_ENV !== 'production' ){
        devLicenseKey = process.env.REACT_APP_AID_LICENSE_KEY;
      }
      console.log('dmv-x', devLicenseKey);
      captureFw = this.IDMetricsCaptureFramework;
      captureFw.setDetectionProvider(2, devLicenseKey).then(function(){});
      window.rootResourcePath = `${process.env.REACT_APP_AID_PROC_MGR_URL}/`;
    }
    window.onCaptureFrameworkLoadFailed = function() {
      console.log("AID Capture Framework Load Failed");
    }
  }
  document.body.appendChild(script);
}

//Invokes aidCapture and returns result as array of fileList
export default async function useAIDCapture(event, settings = null) {
  return new Promise((resolve, reject) => {
    let images;
    const captureResult = new CaptureResult();

    captureResult.setOnFinish(async function() {
      if (captureResult.isSuccessful) {
        if (event === "SELFIE" || event === "PASSPORT") {
          const result = convertResultToBlob(captureResult.result);
          images = await storeImage([result]);
        } else {
          const frontImage = captureResult.result.front;
          const backImage = captureResult.result.back;
          const results = [];
          [frontImage, backImage].forEach((image) => results.push(convertResultToBlob(image)));
          images = await storeImage(results);
        }
        resolve(images);
      }
    });

    captureResult.setOnAborted(function(abortEvent) {
      if (abortEvent.errorType === "user-cancel") {
        resolve();
      }
    });

    captureResult.setOnEvent(function(errorCode, errorDescription, event) {
      console.log("Error Code:", errorCode);
      console.log("Error Description:", errorDescription);
      console.log(event);
      reject(errorCode);
    });

    if (event === "SELFIE") {
      setSelfieSettings(settings);
      window.IDMetricsCaptureFramework.scanSelfie(settings, captureResult);
    } else {
      setDocumentSettings(settings);
      window.IDMetricsCaptureFramework.scanDocument(settings, captureResult);
    }
  });
}

//Store result of aidCapture event in fileList
async function storeImage(images) {
  // //check if promised result is empty
  // if (images == null) {
  //   return;
  // }
  const fileList = [];
  for (let i = 0; i <= images.length - 1; i++) {
    const image = images[i];
    const filename = `mwc-${new Date().getTime()}.jpg`;
    const filePromise = await blobtoFile(image, filename);
    fileList[i] = filePromise;
  }
  return Promise.all(fileList);
}
