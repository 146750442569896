export const status = {
  EXF: "EXF",
  CNT: "CNT",
  PUC: "PUC",
};

export const aidEnabledCatDocIds = [1, 2, 8, 60, 61, 62, 82, 83, 120, 121, 166];
export const multipPageCatDocIds = [2, 60, 8, 62, 83, 121, 166];
export const verificationRequiredCatDocIds = [1, 2, 3, 61, 62, 63, 81, 82, 83, 119, 120, 121];

export const Documents = {
  PASSPORT: "usPassport",
  PASSPORT_CARD: "usPassportCard",
  BIRTH_CERTIFICATE: "usBirthCertificate",
  DRIVERS_LICENSE: "driversLicense",
  SIGNATURE: "signature",
  ID_CARD: "identificationCard"
};

export const isDocument = (document, docType) => 
  document === docType || docType.toLowerCase().includes(document.toLowerCase());

export const isAbbyyFlow = (ATD) => ["DNQ", "EXF", "XAD"].includes(ATD);

export const isMDLMobile = (applicationType) => applicationType === "mdl";

export const autoSubmitOptIn = {
  OPTED_IN: "optedIn",
  OPTED_OUT: "optedOut",
};

export const aidCaptureError = {
  errorCode1: "Face not detected.",
  errorCode2: "Hold still when uploading document to avoid blur.",
  errorCode3: "Avoid glare on the document.",
  errorCode4: "Barcode not detected.",
  errorCode99: "We encountered issues with your images. Please try again.",
};

export const cecBreadCrumbProps = {
  homeTitle: "Home",
  homeLink: process.env.REACT_APP_PORTAL_URL,
  currentPageTitle: "Online Driver's License Renewal Eligibility Check"
}