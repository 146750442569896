import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { APPLICATION_TYPE, mobileSessionDynamic } from '@ca-dmv-radv/data';
import { useTranslation } from '@ca-dmv-radv/translation';
import { getParamUrl } from '@ca-dmv-radv/utilities';
import { Button, BUTTON_STYLE_LINK, ButtonWrapper, Icon, ICON_ID } from '@ca-dmv/core';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { isFirefox, isMobile } from 'react-device-detect';
import { DrunkenSquare } from "@ca-dmv/core";
import { iProovSupport } from "@iproov/web-sdk/iProovSupport.js";
import { generateErrorUrl } from '../dashboard/PresenceVerification/constants';


export default function DynamicMobileSession({ title, applicationType }) {
  const { t } = useTranslation();
  const formProps = { isForm: false };
  const params = useParams();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const iProovSupportChecker = new iProovSupport();

  useEffect(() => {
    setLoading(true);
    
    (async () => {
      const { supported } = await iProovSupportChecker.check();
      const aidUnsupported = isFirefox;

      if (!isMobile || supported === false || aidUnsupported) {
        navigate(`/${applicationType}/error`, {
          state: {
            applicationType: applicationType,
            isMobile: isMobile,
            supported: (supported && !aidUnsupported)
          },
        });
        return;
      }

      if (isMobile && supported) {
        (async () => {
          let success;
          let message;
          let data;
    
          try {
            ({ success, message, data } = await mobileSessionDynamic(params.token, applicationType));
            if (success === true) {
              setLoading(false)
              if (applicationType === APPLICATION_TYPE.NCH) {
                window.location.href = `/nch/`;
              } else {
                window.location.href = getParamUrl(
                  `/${applicationType}/presence-verification`,
                  params
                );
              }
            } else {
              if (applicationType === APPLICATION_TYPE.MDL && message === 'OK') {
                window.location.href = getParamUrl(`/${applicationType}/document-upload`, params);
              }
              setErrorMessage(message);
            }
          } catch (error) {
            setLoading(false)
              if (error.response) {
                setErrorMessage(error.response?.data?.data?.error);
              }
    
              const url = generateErrorUrl(error, applicationType);

              if (error.response?.status === 401 || error.response?.status === 422) {
                navigate(url, { state: { token: error.response?.data?.data?.token, status: error.response.status } });
              } else {
                navigate(url);
              }
              return;
          }
        })(); 
      } 
    })();
  }, []);

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
    >
     {loading && <>Loading.....</>}
      <div className="flex flex--col flex--align-center">
        {!loading && errorMessage && (
          <>
            <h2 className="h3 text--xmd bp-sm:text--med bp-md:text--xlg text--700 text--center mb-24 bp-md:mb-48">
              {t("screens-error-heading", "Oops, something went wrong")}
            </h2>
            <p className="text--sm mb-0 text--center max-width--400">
              {errorMessage}
            </p>
          </>
        )}
        {!loading && errorMessage && <ButtonWrapper wrapperClass="flex--col-reverse mt-40 bp-md:mt-60">
          <Button
            label={t(
              "screens-Timeout-buttonLabel-ReturnToDMVHomePage",
              "Return to the DMV Homepage"
            )}
            btnStyle={BUTTON_STYLE_LINK}
            buttonClass="mb-0 bp-md:mr-40"
            onClick={() => {
              window.location = process.env.REACT_APP_PORTAL_URL;
            }}
          />
          <Button
            buttonClass="mb-40 bp-md:mb-0"
            label={t(
              "screens-Timeout-buttonLabel-ReturnToApplication",
              "Return to Application"
            )}
            onClick={() => {
              window.location = process.env.REACT_APP_EDL_URL;
            }}
          />
        </ButtonWrapper>}
      </div>
    </RadvPageWrapper>
  );
}
