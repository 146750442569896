import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMounted } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import { getApplicationDocUploads } from "../api";
import {
  DOCUMENT_CATEGORY_IDENTITY,
  DOCUMENT_CATEGORY_RELATIONSHIP,
  DOCUMENT_CATEGORY_RESIDENCY,
  DOCUMENT_CATEGORY_NAME_CHANGE,
  FETCH_INTERVAL,
  APPLICATION_TYPE,
  DOCUMENT_CATEGORY_MDL_IDENTITY,
  DOCUMENT_CATEGORY_DRIVERS_LICENSE,
  DOCUMENT_CATEGORY_NCH_NAME_TRACING,
} from "../constants";
import { useThrowFetchError } from "../error-context-provider";
import { useApplication } from "../application-context-provider";

const DocumentUploadsContext = React.createContext();

export function DocumentUploadsContextProvider({
  children,
  initialDocumentUploads,
}) {
  const { applicationType } = useApplication();
  const [documentUploads, setDocumentUploads] = useState(
    initialDocumentUploads
  );
  const [stringifiedDocumentUploads, setStringifiedDocumentUploads] =
    useState();
  const [currentFocusedDoc, setCurrentFocusedDoc] = useState(null);
  const [hasStartedPoR, setHasStartedPoR] = useState(false);
  const [hasStartedPoI, setHasStartedPoI] = useState(false);
  const shouldPingDocumentUploads = useRef(true);
  const fetchingDocumentUploads = useRef(false);
  const throwFetchError = useThrowFetchError();
  const mounted = useMounted();
  const { t } = useTranslation();
  const [isMDLDocument] = useState(applicationType === APPLICATION_TYPE.MDL);

  /**
   * If the stringified document uploads have changed, update the document uploads state. This
   * approach allows us to prevent unnecessary re-renders that would result from updating the
   * documentUploads object every time the data is fetched in the interval below.
   */
  useEffect(() => {
    if (
      stringifiedDocumentUploads &&
      stringifiedDocumentUploads !== JSON.stringify(documentUploads)
    ) {
      setDocumentUploads(JSON.parse(stringifiedDocumentUploads));
    }
  }, [stringifiedDocumentUploads]);

  const setShouldPingDocumentUploads = (shouldPing) => {
    shouldPingDocumentUploads.current = shouldPing;
  };

  const fetchDocumentUploads = useCallback(async () => {
    let data;
    let success;
    try {
      const applicationDocUploadFn = getApplicationDocUploads;
      ({ data, success } = await applicationDocUploadFn(applicationType));

      if (!success) {
        throw new Error("fetchDocumentUploads");
      }
      if (mounted.current && Object.keys(data).length > 0) {
        setStringifiedDocumentUploads(JSON.stringify(data));
      }
    } catch (error) {
      setShouldPingDocumentUploads(false);
      throwFetchError({
        error,
        message: t(
          "app-error-fetchingData-documentUploads",
          "There was an error fetching your document data. Try again."
        ),
      });
    }

    return success;
  }, [throwFetchError, t]);

  /**
   * Refetch document uploads at the interval specified in the FETCH_INTERVAL constant.
   */
  const useDocumentUploadPing = useCallback(() => {
    useEffect(() => {
      const intervalCallback = async () => {
        if (
          !shouldPingDocumentUploads.current ||
          fetchingDocumentUploads.current
        ) {
          return;
        }

        fetchingDocumentUploads.current = true;
        await fetchDocumentUploads();
        fetchingDocumentUploads.current = false;
      };

      const interval = window.setInterval(intervalCallback, FETCH_INTERVAL);

      intervalCallback();

      return () => {
        window.clearInterval(interval);
      };
    }, []);
  }, []);

  const identityCategory = isMDLDocument? DOCUMENT_CATEGORY_MDL_IDENTITY: DOCUMENT_CATEGORY_IDENTITY;
  const nameChangeCategory = applicationType === APPLICATION_TYPE.NCH ? DOCUMENT_CATEGORY_NCH_NAME_TRACING : DOCUMENT_CATEGORY_NAME_CHANGE;
  return (
    <DocumentUploadsContext.Provider
      value={useMemo(
        () => ({
          documentUploads: documentUploads || [],
          fetchDocumentUploads,
          identityDocumentUploads:
            documentUploads?.[String(identityCategory)] || [],
          nameChangeDocumentUploads:
            documentUploads?.[String(nameChangeCategory)],
          relationshipDocumentUploads:
            documentUploads?.[String(DOCUMENT_CATEGORY_RELATIONSHIP)] || [],
          residencyDocumentUploads:
            documentUploads?.[String(DOCUMENT_CATEGORY_RESIDENCY)] || [],
          driversLicenseDocumentUploads:
            documentUploads?.[String(DOCUMENT_CATEGORY_DRIVERS_LICENSE)] || [],
          currentFocusedDoc,
          hasStartedPoR,
          hasStartedPoI,
          setDocumentUploads,
          setShouldPingDocumentUploads,
          useDocumentUploadPing,
          setCurrentFocusedDoc,
          setHasStartedPoR,
          setHasStartedPoI,
          isMDLDocument
        }),
        [
          isMDLDocument,
          documentUploads,
          currentFocusedDoc,
          hasStartedPoR,
          hasStartedPoI,
          fetchDocumentUploads,
          useDocumentUploadPing,
        ]
      )}
    >
      {children}
    </DocumentUploadsContext.Provider>
  );
}

export function useDocumentUploads() {
  return useContext(DocumentUploadsContext);
}

export * from "./constants";
