import React from "react";
import { Modal } from "@ca-dmv/modal";
import { Button, BUTTON_STYLE_SECONDARY, Loading } from "@ca-dmv/core";
import useVerificationFieldsValidation from "../Upload/useVerificationFieldsValidation";
import VerificationFields from "../Upload/VerificationFields";
import { updateDocumentData, useApplication, useDocumentUploads, useFileUpload } from "@ca-dmv-radv/data";
import { useNavigate } from 'react-router-dom';
import { isAbbyyFlow, isDocument, Documents } from '../helpers/constants';

export default function MobileVerificationModal({
  t,
  documentUpload,
  onClose,
  setVerificationRequired,
  setShowIdentityForm,
  extractedDetails,
  setConfirmFields,
  extractInProgress,
  setRequiredPhotos,
  applicationType
}) {
  const { validBCForm, isValidPassportForm, isValidExpirationDateForm } =
    useVerificationFieldsValidation();
    const navigate = useNavigate();
  const { passportFormData, showInputError } = useFileUpload();
  const { documentTypes } = useApplication();

  const determineIsNextDisabled = () => {
    const isValidForm = isDocument(Documents.BIRTH_CERTIFICATE, documentTypes[documentUpload.catDocId]) 
      ? !validBCForm 
      : documentUpload.requiredExpirationDate
      ? isValidExpirationDateForm
      : isValidPassportForm(documentUpload.catDocId, documentUpload.ATD);
    return !isValidForm || showInputError;
  }

  const isNextDisabled = determineIsNextDisabled();

  const { fetchDocumentUploads, isMDLDocument } = useDocumentUploads();

  const handleSubmit = () => {
    if (!isDocument(Documents.BIRTH_CERTIFICATE, documentTypes[documentUpload.catDocId])) {
      if (!isAbbyyFlow(documentUpload.ATD) && Object.keys(extractedDetails)?.length) {
        updateDocumentData(documentUpload.id, documentUpload.catDocId, passportFormData, applicationType);
        setConfirmFields(true);
        isMDLDocument && navigate(`../final-confirmation`)
      }
    }
    setVerificationRequired("accepted");
    setRequiredPhotos(null)
    setShowIdentityForm(false);
    fetchDocumentUploads();
  }

  return (
    <Modal
      modalTitle=""
      contentContainerClass={"bp-lg:pl-30 bp-lg:pr-30 bp-lg:pb-30"}
      onClose={onClose}
    >
      {extractInProgress && (
        <>
          <p className="text--lh-sm text--blue-dark-2 text--700 mt-30 mb-40 text--center">
            {t(
              "screens-Dashboard-Modal-ProcessingExtractDocument-subtitle",
              "Extracting Document Information"
            )}
          </p>

          <Loading containerClass={"flex--justify-center"} loadingText="Please Wait..." />
        </>
      )}

      {!extractInProgress && (<>
        <VerificationFields documentUpload={documentUpload} extractedDetails={extractedDetails} />
        <div className={"flex flex--align-center flex--justify-" + 
          (isAbbyyFlow(documentUpload.ATD) ? "between" : "end") + " mt-40"}>
          {isAbbyyFlow(documentUpload.ATD) && (<Button
            label={t(
              "screens-ProofOfIdentity-Modal-Upload-buttonCancelLabel",
              "Cancel"
            )}
            btnStyle={BUTTON_STYLE_SECONDARY}
            onClick={onClose}
          />)}
          <Button
            label={isAbbyyFlow(documentUpload.ATD) ? 
            t(
              "screens-ProofOfIdentity-Modal-Upload-buttonNextLabel",
              "Next")
            :
            t(
              "screens-ProofOfIdentity-Modal-Upload-buttonConfirmLabel",
              "Confirm")
            }
            disabled={isNextDisabled}
            onClick={handleSubmit}
          />
        </div>
      </>)}
    </Modal>
  );
}
