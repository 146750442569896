import { RadvPageWrapper } from '@ca-dmv-radv/components';
import { APPLICATION_TYPE } from '@ca-dmv-radv/data';
import { Trans, useTranslation } from '@ca-dmv-radv/translation';
import { ButtonWrapper, Button } from '@ca-dmv/core';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cecBreadCrumbProps } from '../dashboard/helpers/constants';

function CECOnlineRenewal({ isEligible }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Check that user has submitted DL details
  useEffect(() => {
    console.log("isEligible value: " + isEligible);
    if (isEligible === undefined) {
      navigate("/error");
    }
  }, []);

  return (
    <div className="border border--gray p-20 bp-md:p-30 mb-0">
      <h2 className="text--h3 m-0 flex--grow-1 flex--shrink-1 w--auto mb-24 bp-md:mb-48">
        {t(`cec-eligible-text-${isEligible}`, "Renew Online")}
      </h2>
      <p>{t(`cec-eligible-subtext-${isEligible}`, "You are eligible to renew your driver's license online.")}</p>
      {!isEligible && (
        <p>{t("cec-ineligible-save-time", "Start your driver's license renewal application online to save time.")}</p>
      )}
      <ButtonWrapper wrapperClass="flex--col-reverse mt-24 mb-24">
        <Button
          label={t(`cec-eligible-btn-text-${isEligible}`, "Start Application")}
          onClick={() => {
            window.location = isEligible 
              ? process.env.REACT_APP_DL_ID_RENEWAL_URL
              : process.env.REACT_APP_EDL_URL
          }} 
        />
      </ButtonWrapper>
      {isEligible && (
        <p className='mb-16'>
          {t(
            "cec-eligible-online-renewal-info-text",
            "Driver's License/ID Card Renewals are no longer processed at the DMV office and can be done quickly and easily online."
          )}
        </p>
      )}
      <p>
        <Trans
          i18nKey={"cec-eligible-renewal-faq"}
          defaults="For more information, visit <a>License Renewal FAQs.</a>"
          components={{ a: <a href={process.env.REACT_APP_LICENSE_RENEWAL_FAQS_URL} /> }}
        />
      </p>
    </div>
  )
}

export default function DynamicFinalConfirmation({ title, applicationType }) {
  const { t } = useTranslation();
  const location = useLocation();
  const formProps = { isForm: false };
  const headingText = {
     [APPLICATION_TYPE.MDL]: {
      key: "screens-MDL-FinalConfirmation-subtitle",
      value: "Your documents have been submitted for verification."
     },
     [APPLICATION_TYPE.ODP]: {
      key: "screens-ODP-FinalConfirmation-subtitle",
      value: "Your verification is complete."
     },
     [APPLICATION_TYPE.VDL]: {
      key: "screens-VDL-FinalConfirmation-subtitle",
      value: "Your document has been submitted for verification."
     },
  };

  return (
    <RadvPageWrapper
      fullWidth
      formProps={formProps}
      showRequiredFieldSeparator={false}
      pageTitle={title}
      pageHeading={applicationType === APPLICATION_TYPE.CEC && t("cec-screens-finalConfirmation-heading", "Online Renewal Eligibility")}
      breadCrumbs={applicationType === APPLICATION_TYPE.CEC && cecBreadCrumbProps}
    >
      {applicationType === APPLICATION_TYPE.CEC 
        ? <CECOnlineRenewal isEligible={location?.state?.isEligible} />
        : (
            <div className="max-width--800">
              <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
                {t(headingText[applicationType].key, headingText[applicationType].value)}
              </h3>
              <h3 className="text--primary text--md text--blue-dark-2 text--700 mb-24">
                {t("screens-MDL-FinalConfirmation-text", "It is now safe to close the browser window.")}
              </h3>
            </div>
          )
      }
    </RadvPageWrapper>
  );
}
