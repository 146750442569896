import React from "react";
import { Note } from "@ca-dmv/core";
import { useTranslation } from "@ca-dmv-radv/translation";
import { isDocument, Documents } from "../helpers/constants";
import { useApplication } from "@ca-dmv-radv/data";

export default function UploadInstructions({ document = null }) {
  const { t } = useTranslation();
  const { documentTypes } = useApplication();

  // check to see if the selected document is a U.S. Birth Certificate
  const provideDocumentsThatContain =
    isDocument(Documents.BIRTH_CERTIFICATE, documentTypes[document.catDocId])
      ? t(
          "screens-ProofOfIdentity-Modal-Upload-birth-certificate-noteLine2",
          "Only provide documents that contain relevant information, such as your ‘State of Birth, State File Number, Registrar Number’."
        )
      : t(
          "screens-ProofOfIdentity-Modal-Upload-noteLine2",
          "Only provide documents that contain relevant information, such as your true full name."
        );

  return (
    <aside className="mt-24">
      <Note label={t("shared.noteText", "Note")}>
        <ul className="mt-10 mb-0 pl-20">
          {!isDocument(Documents.SIGNATURE, documentTypes[document.catDocId]) && (
            <li>
              {t(
                "screens-ProofOfIdentity-Modal-Upload-noteLine1",
                "Make sure each image being uploaded is clear and readable."
              )}
            </li>
          )}
          {!isDocument(Documents.SIGNATURE, documentTypes[document.catDocId]) && <li>{provideDocumentsThatContain}</li>}
          {isDocument(Documents.SIGNATURE, documentTypes[document.catDocId]) ? (
            <li>
              {t(
                "screens-ProofOfIdentity-Modal-Upload-signature-noteLine3",
                "Acceptable formats: .png .jpg .pdf .tif .gif .heic"
              )}
            </li>
          ) : (
            <li>
              {t(
                "screens-ProofOfIdentity-Modal-Upload-noteLine3",
                "Acceptable formats: .png, .jpg, .pdf, .tif, .gif, .heic."
              )}
            </li>
          )}

          <li>
            {t(
              "screens-ProofOfIdentity-Modal-Upload-noteLine4",
              "Maximum file size: 5MB."
            )}
          </li>
        </ul>
      </Note>
    </aside>
  );
}
