import {
  useDocumentUploads,
  useGoogleAnalytics,
  getDocumentSectionStatus,
  getOptedCategory,
  getNameChanges,
  useNameChanges,
  useIdentityDocument,
  parseReceivedNameChanges,
  updateDocumentByCategory,
  APPLICATION_TYPE,
  DOCUMENT_CATEGORY_NCH_NAME_TRACING
} from "@ca-dmv-radv/data";
import { Trans, useTranslation } from "@ca-dmv-radv/translation";
import {
  Alert,
  ALERT_ERROR,
  ALERT_INFO,
  ALERT_WARNING,
  Button,
  ICON_SMALL,
  ICON_STOP,
  SelectMenu,
  Separator,
  STATUS_ERROR,
  STATUS_IN_PROGRESS,
  STATUS_INCOMPLETE,
  STATUS_READY,
  STATUS_REVIEW,
  STATUS_SUBMITTING,
  STATUS_VERIFYING,
  StatusContainer,
} from "@ca-dmv/core";
import classNames from "classnames";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Document from "../GenericDocument";
import getDocumentUploadStatusFlag from "../helpers/getDocumentUploadStatusFlag";
import getIdentityStatus from "../../../../data/src/identity-document-context-provider/getIdentityStatus";
import { useSortedDocuments } from "@ca-dmv-radv/utilities";
import { useThrowErrorSavingData } from "@ca-dmv-radv/data";
import { showStatus } from "../../mdl/utils";
import OptionalDocumentPrompt from "./OptionalDocumentPrompt";
import NameChangeUploads from "./NameChangeUploads";
import { isPendingConfirmationStatus } from "../PresenceVerification/constants";
import DriversLicenseForm from "../../cec-dl-form";
import { categoryIds, isApplicationNch } from "../../constants";

const DOCUMENT_CATEGORY = {
  9: "identity",
  10: "signature",
  11: "nameChange",
};

export default function ProofOfDocument({
  errors,
  selectListOfDocs,
  proofOfDocumentCategory,
  application,
  applicationType,
  recaptchaRef,
}) {
  const {
    setCurrentFocusedDoc,
    setHasStartedPoI,
    fetchDocumentUploads,
    nameChangeDocumentUploads,
  } = useDocumentUploads();
  const { 
    setSelectedNameMatchesOption, 
    nameMatches,
    setNameMatches, 
    hasNameChangeDocs,
    setHasNameChangeDocs, 
    noDocsOption,
    setNoDocsOption, 
    setDocumentType,
    clearNameChange,
    setNameChanges,
    nameChanges,
  } = useNameChanges();
  const { t } = useTranslation();
  const [hint, setHint] = useState();
  const [navigating, setNavigating] = useState(false);
  const { setAnalyticsAction } = useGoogleAnalytics();
  const { documentUploads } = useDocumentUploads();
  const [confirmFields, setConfirmFields] = useState(false);
  const [showIdentityForm, setShowIdentityForm] = useState(false);
  const [showSelected, setshowSelected] = useState(false);
  const throwErrorSavingData = useThrowErrorSavingData();
  const [optedIn, setOptedIn] = useState(null);
  const [willUpload, setWillUpload] = useState(optedIn);
  const navigate = useNavigate();
  const previousSelectedProofOfDocumentRef = useRef();
  const previousSelectedProofOfDocument = previousSelectedProofOfDocumentRef.current;

  const [categoryDocumentUploads, setCategoryDocumentUploads] = useState(documentUploads[proofOfDocumentCategory.categoryId] || []);
  const categoryDocuments = proofOfDocumentCategory.documents;
  const allSectionDocs = applicationType === APPLICATION_TYPE.NCH && categoryDocumentUploads && proofOfDocumentCategory.categoryId === categoryIds.NCH_NAME_CHANGE
    ? [...categoryDocumentUploads, ...(documentUploads[DOCUMENT_CATEGORY_NCH_NAME_TRACING] || [])]
    : categoryDocumentUploads;
  const [selectedProofOfDocument, setSelectedProofOfDocument] = useState(() =>
    categoryDocuments.find((doc) => doc.id === categoryDocumentUploads?.[0]?.catDocId)
  );

  const isNch = isApplicationNch(applicationType);
  const identityStatus = isNch ? documentUploads[categoryIds["NCH_IDENTITY"]]?.[0]?.statusId : null;
  const isAwaitingIdentityDocUpload = isNch ?
      proofOfDocumentCategory.categoryId === categoryIds["NCH_NAME_CHANGE"] &&
      application.skipDMV &&
      identityStatus === 1 : 
      false;

  const nameOnApplication = application?.application?.name;
  const nameOnFile = application?.application?.nameOnFile;

  const shouldShowInfoMessage = (infoMessage, proofOfDocumentCategory) => {
    const hasInfoMessage = infoMessage !== null && infoMessage.trim();
    const isNotSignatureCategory = proofOfDocumentCategory.categoryId !== 10;
    return hasInfoMessage && isNotSignatureCategory;
  }

  const documentStatus = (categoryDocumentUploads && optedIn && selectedProofOfDocument) 
    ? (categoryDocumentUploads?.[0]?.catDocId !== selectedProofOfDocument?.id)
    ? STATUS_IN_PROGRESS
    : getDocumentSectionStatus(allSectionDocs) 
    : "";

  const shouldShowSelect = ["", STATUS_IN_PROGRESS].includes(documentStatus) && 
    (categoryDocumentUploads?.[0]?.catDocId !== selectedProofOfDocument?.id ||
      !selectedProofOfDocument || 
      (proofOfDocumentCategory.categoryId === categoryIds["NCH_NAME_CHANGE"] && (nameMatches === null ||
        (nameMatches === false && noDocsOption === null && (!hasNameChangeDocs || !nameChangeDocumentUploads?.length)))
      )
    );

  const navigateToNameConfirmation = () =>
    selectedProofOfDocument?.allowTracingNames ||
    (!selectedProofOfDocument?.allowTracingNames &&
      proofOfDocumentCategory.categoryId === categoryIds["NCH_NAME_CHANGE"]);

  const postSelectedDocument = async () => {
    let success;
    try {
      ({ success } = await updateDocumentByCategory(
        { 
          categorizedDocumentId: selectedProofOfDocument.id, 
          categoryId: proofOfDocumentCategory.categoryId,
          documentType: DOCUMENT_CATEGORY[proofOfDocumentCategory.categoryId]
        },
        applicationType
      ));

      if (!success) {
        throw new Error();
      }
    } catch (error) {
      throwErrorSavingData({
        error,
        message: t(
          `${applicationType}-app-error-savingData-category${proofOfDocumentCategory.categoryId}-documents`,
          `There was an error saving your ${proofOfDocumentCategory.categoryName} document selection. Try again.`
        ),
      });
    }

    return success
  }

  const requestNavigation = async () => {
    if (navigating) {
      return;
    }

    setNavigating(true);
    setAnalyticsAction(`Submit ${proofOfDocumentCategory.categoryName} Doc`);
    const success = await postSelectedDocument();
    const navigateStateObj = {
      proofOfDocumentCategory: proofOfDocumentCategory,
      selectedDocument: selectedProofOfDocument,
    };
    setCurrentFocusedDoc(`proof-of-${proofOfDocumentCategory.categoryId}`);
    setHasStartedPoI(true);
    if (success && navigateToNameConfirmation()) {
      setSelectedNameMatchesOption(null);
      navigate("/name-confirmation", {
        state: navigateStateObj
      });
    } else {
      setshowSelected(true);
      fetchDocumentUploads();
      setNavigating(false);
    }
  };

  useEffect(() => {
    // TODO: Figure out how to not hardcode. documentType is used in name-changes-context-provider 
    // & should only be set by the category that allow tracing name change documents. Without current
    // condition, the documentType gets overrided by any other document category in the business program
    // since this is a generic category tile component.
    if (isNch && DOCUMENT_CATEGORY[proofOfDocumentCategory.categoryId] === "nameChange") {
      setDocumentType(DOCUMENT_CATEGORY[proofOfDocumentCategory.categoryId]);
    }
  }, []);

  useEffect(() => {
    if (isNch && 
      DOCUMENT_CATEGORY[proofOfDocumentCategory.categoryId] === "nameChange" &&
      previousSelectedProofOfDocument &&  
      previousSelectedProofOfDocument !== selectedProofOfDocument) {
        setNameMatches(null);
        setNameChanges(parseReceivedNameChanges([], application, DOCUMENT_CATEGORY[proofOfDocumentCategory.categoryId]));
        setHasNameChangeDocs(null);
        setNoDocsOption(null);
        clearNameChange(0);
    }
  }, [selectedProofOfDocument, previousSelectedProofOfDocument]);

  useEffect(() => {
    // For CEC-only
    if (applicationType === APPLICATION_TYPE.CEC) {
      setOptedIn(willUpload);
      return;
    }

    const fetchOptedCategory = async () => {
      const { data: {success, data} } = await getOptedCategory(applicationType, proofOfDocumentCategory.categoryId);
      if (success) {
        setOptedIn(data.optedIn);
      } else {
        setOptedIn(true);   // if category is not optional, default to true
      }
      fetchDocumentUploads();
    }

    fetchOptedCategory();
  }, [willUpload]);

  const showSubheading = [
    "",
    STATUS_IN_PROGRESS,
    STATUS_VERIFYING,
    STATUS_ERROR,
    STATUS_INCOMPLETE,
  ].includes(documentStatus);

  const showVerificationMessage = [STATUS_VERIFYING, STATUS_REVIEW].includes(documentStatus);

  const showOptionalPrompt = ["", STATUS_IN_PROGRESS, STATUS_ERROR].includes(documentStatus);

  const subHeadingClass = classNames("max-width--600 text--blue-dark-2");

  const statusContainerClass = classNames("mb-40 bp-md:mb-48 max-width--900");

  const sortedCategoryDocumentOptions = useSortedDocuments({
    documents: categoryDocuments
  });

  const nameToDisplay = (nameOne, nameTwo, categoryId) => {
    if (categoryId === categoryIds.NCH_NAME_CHANGE) {
      let displayName = `${nameOne} ${nameTwo}`;
      return displayName.trim();
    } else {
      return nameOne;
    }
  }

  const updateProofOfDocument = (val) => {
    setSelectedProofOfDocument(
      categoryDocuments.find((doc) => doc.documentId === Number(val))
    );
  };
  const infoMessage = t(
    `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-infoMessage`, 
    `${proofOfDocumentCategory.categoryName} informational message.`
  );

  const heading = t(
    `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-legend`, 
    `Proof of ${proofOfDocumentCategory.categoryName}`
  );

  useEffect(() => {
    const allOptions = [];
    for (let i = 0; i < sortedCategoryDocumentOptions.length; i += 1) {
      allOptions.push(sortedCategoryDocumentOptions[i]);
    }
    const selectedObject = allOptions.find(
      (option) => option.value === selectedProofOfDocument?.documentId
    );
    if (selectedObject) {
      setHint(selectedObject.hint);
    } else {
      setHint("");
    }
  }, [selectedProofOfDocument]);

  useEffect(() => {
    if (
      documentUploads &&
      typeof categoryDocumentUploads === "undefined"
    ) {
      setshowSelected(false);
    }
    if (documentUploads[proofOfDocumentCategory.categoryId]) {
      setCategoryDocumentUploads(documentUploads[proofOfDocumentCategory.categoryId]);
    }
  }, [documentUploads]);

  useEffect(() => {
    setSelectedProofOfDocument(() =>
      categoryDocuments.find((doc) => doc.id === categoryDocumentUploads?.[0]?.catDocId)
    );

    // Sync signature opt-in/opt-out decision if active on multiple devices at the same time
    if (categoryDocumentUploads?.[0]?.categoryId === categoryIds["NCH_SIGNATURE"]) {
      // A dummy signature record (inserted when user has not made a decision or has opted out) has a statusId of 0
      if (categoryDocumentUploads?.[0]?.statusId !== 0) {
        setOptedIn(true);
      } else if (categoryDocumentUploads?.[0]?.optedDecision === "OptedOut") {
        setOptedIn(false);
      }
    }
  }, [categoryDocumentUploads]);

  return (
    <StatusContainer
      containerClass={statusContainerClass}
      heading={
        <h3 className="mb-24 text--primary">
          {heading}
        </h3>
      }
      headingClass="mb-24"
      subHeading={
        <Trans
          i18nKey={
             `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-subLegend`
          }
          defaults={`<p1>Proof of ${proofOfDocumentCategory.categoryName} documents must be original or certified copies.</p1><p2>Once your documents have been uploaded or photographed, you will not be able to select a different document.</p2>`}
          components={{
            p1: <span className="show mb-16" />,
            p2: <span className="show mb-0" />,
          }}
        />
      }
      status={
        applicationType === APPLICATION_TYPE.CEC ? "" : documentStatus !== STATUS_SUBMITTING ? documentStatus : STATUS_READY
      }
      showSubheading={showSubheading}
      subHeadingClass={subHeadingClass}
    >
      {shouldShowInfoMessage(infoMessage, proofOfDocumentCategory) && (
        <Alert
          alertStyle={ALERT_INFO}
          ariaLive="on"
          containerClass="mt-24 mb-24 bp-md:max-width--900 bp-lg:max-width--900"
        >
          <Trans 
            i18nKey={
              `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-infoMessage`
            }
            defaults={infoMessage}
            components={{
              p: <span className="show mb-0 text--sm" />,
              b: <b />  
            }}
            values={{
              firstName: nameToDisplay(nameOnApplication?.firstName, nameOnApplication?.middleName, proofOfDocumentCategory.categoryId),
              lastName: nameToDisplay(nameOnApplication?.lastName, nameOnApplication?.suffix, proofOfDocumentCategory.categoryId),
              firstNameOnFile: application.application?.nameOnFile?.firstName || "",
              lastNameOnFile: application.application?.nameOnFile?.lastName || ""
            }}
          />
        </Alert>
      )}
      {isAwaitingIdentityDocUpload && (
        <Alert
          alertStyle={ALERT_WARNING}
          ariaLive="off"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-nch-Dashboard-UploadIdentityDocs",
              "Upload your identity documents before proceeding to this section."
            )}
          </p>
        </Alert>
      )}
      {showVerificationMessage && (
        <Alert
          alertStyle={ALERT_WARNING}
          ariaLive="off"
          containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
          hasBorder={false}
        >
          <p className="mb-0">
            {t(
              "screens-Dashboard-verificationMessage",
              "You will receive an email when your documents have been verified. This can take up to 2 business days."
            )}
          </p>
        </Alert>
      )}
      {(proofOfDocumentCategory.required === false || applicationType === APPLICATION_TYPE.CEC) && showOptionalPrompt && (
        <OptionalDocumentPrompt 
          applicationType={applicationType} 
          proofOfDocumentCategory={proofOfDocumentCategory}
          setWillUpload={setWillUpload}
          optedIn={optedIn}
        />
      )}
      {optedIn && (
        <>
          {shouldShowSelect && !showSelected ? (
            <>
              <div className="js-doc-wrap flex flex--col bp-md:flex--row  flex--nowrap flex--justify-center flex--align-center bp-sm:flex--align-start bp-md:flex--align-start bp-md:flex--justify-start">
                <SelectMenu
                  containerClass="bp-md:mb-5 w--100 bp-md:w--auto max-width--400"
                  selectClass="w--100 bp-md:max-width--400"
                  label={t(
                    `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-label`,
                    `Proof of ${proofOfDocumentCategory.categoryName} Document`
                  )}
                  hint={hint}
                  selectPlaceholder={t(
                    `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-selectDocumentPlaceholder`,
                    `Select Proof of ${proofOfDocumentCategory.categoryName} Document`
                  )}
                  options={sortedCategoryDocumentOptions}
                  selectedValue={selectedProofOfDocument?.documentId}
                  onChange={updateProofOfDocument}
                  error={errors}
                  // hasFocus={
                  //   focusedElementKey && focusedElementKey === "proofOfIdentity"
                  // }
                  hideAsterisk={true}
                  disabled={isAwaitingIdentityDocUpload}
                />
                {selectedProofOfDocument?.id && (
                  <div>
                    <Button
                      isLoading={navigating}
                      buttonClass="mt-24 bp-md:ml-24"
                      label={t(
                        `screens-${proofOfDocumentCategory.categoryId}-continueButtonLabel`,
                        "Continue"
                      )}
                      onClick={() => {
                        localStorage.setItem("showNameOptions", true);
                        requestNavigation();
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <Document
                status={getDocumentUploadStatusFlag(
                  categoryDocumentUploads[0],
                  documentUploads,
                  false
                )}
                isCancelable
                onCancelDocument={() => {
                  previousSelectedProofOfDocumentRef.current = selectedProofOfDocument;
                  setshowSelected(false);
                  setSelectedProofOfDocument(null);
                }}
                documentUpload={categoryDocumentUploads[0]}
                showSelectDifferentDocument={shouldShowSelect}
                confirmFields={confirmFields}
                setConfirmFields={setConfirmFields}
                showIdentityForm={showIdentityForm}
                setShowIdentityForm={setShowIdentityForm}
                selectListOfDocs={selectListOfDocs}
                recaptchaRef={recaptchaRef}
                containerClass="js-doc-wrap"
              />
              {categoryDocumentUploads.length > 1 && (
                <div className="bp-md:ml-30">
                  <Separator
                    heading={t(
                      `${applicationType}-screens-category${proofOfDocumentCategory.categoryId}-relatedDocumentsLabel`,
                      "Related Documents for {{selectedDocument}}",
                      {
                        selectedDocument: selectedProofOfDocument?.name,
                      }
                    )}
                    containerClass="mt-40 mb-8"
                  />
                  <Document
                    containerClass="mb-0 js-doc-wrap"
                    documentUpload={categoryDocumentUploads[1]}
                    status={getDocumentUploadStatusFlag(categoryDocumentUploads[1])}
                    showIdentityForm={showIdentityForm}
                    setShowIdentityForm={setShowIdentityForm}
                  />
    
                  <Document
                    containerClass="mt-30 mb-0 js-doc-wrap"
                    documentUpload={categoryDocumentUploads[2]}
                    status={getDocumentUploadStatusFlag(categoryDocumentUploads[2])}
                    showIdentityForm={showIdentityForm}
                    setShowIdentityForm={setShowIdentityForm}
                  />
                </div>  
              )}
              {(selectedProofOfDocument?.allowTracingNames && (nameChangeDocumentUploads?.length > 0 || noDocsOption === 1)) && (
                <NameChangeUploads
                  nameChangeCatDocData={selectedProofOfDocument}
                  proofOfDocumentCategory={proofOfDocumentCategory}
                />
              )}
            </>
          )} 
        </>
      )}

      {applicationType === APPLICATION_TYPE.CEC && willUpload === false && (
        <DriversLicenseForm applicationType={applicationType} recaptchaRef={recaptchaRef} />
      )}
      
      {isPendingConfirmationStatus(categoryDocumentUploads[0]?.ATD) &&
        !confirmFields && selectedProofOfDocument?.id === categoryDocumentUploads[0]?.catDocId && (
          <Alert
            alertStyle={ALERT_ERROR}
            alertIcon={ICON_STOP}
            iconSize={ICON_SMALL}
            ariaLive="off"
            containerClass="mt-24 mb-24 bp-md:max-width--500 bp-lg:max-width--600"
            hasBorder={false}
          >
            <p className="mb-0">
              {t(
                "screens-Dashboard-unconfirmedMessage",
                "We are unable to confirm the details on your document. Please try again."
              )}
            </p>
          </Alert>
        )}
    </StatusContainer>
  );
}
