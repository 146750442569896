import React, { useState, useEffect } from 'react'
import { Button, ButtonWrapper, PATTERN_DRIVERS_LICENSE, InputGroup, InputText, DatePicker } from '@ca-dmv/core'
import { useTranslation } from "@ca-dmv-radv/translation";
import { verifyEligibility } from '@ca-dmv-radv/data'
import { useNavigate } from 'react-router-dom'
import { validateDL } from '../constants';

export default function DriversLicenseForm({ applicationType, recaptchaRef }) {
  const { t } = useTranslation();
  const [lastName, setLastName] = useState('');
  const [dlNumber, setDLNumber] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');
  const [year, setYear] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
		let obj = { dlNumber, lastName, month, day, year };
    setIsValid(validateDL(obj))
  }, [dlNumber, lastName, month, day, year]);

  const onClick = async () => {
    setIsLoading(true);
    const recaptchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    const payload = {
      dlNumber: dlNumber,
      lastName: lastName,
      dob: `${year}-${month}-${day}`, 
      recaptchaToken: recaptchaToken,
      source: "ManualInput"
    };
    await verifyEligibility(payload).then((response) => {
      setIsLoading(false);
      navigate("/result", { state: { isEligible: response.success } })
    });
  }

  return (
    <InputGroup
      containerClass="mb-12"
      required={false}
    >
      <InputText
        containerClass='w--100 mb-24 mt-12'
        required
        label={t("cec-screens-manual-input-last-name", "Last Name")}
        onChange={setLastName}
        value={lastName}
        hintClass='text--xxsm mb-0 mt-2'
      />
      <InputText
        containerClass='w--100 mb-24 mt-12'
        inputClass="text--uppercase"
        required
        label={t("cec-screens-manual-input-dlNumber", "Driver's License Number")}
        pattern={PATTERN_DRIVERS_LICENSE}
        onChange={setDLNumber}
        value={dlNumber.toUpperCase()}
        hintClass='text--xxsm mb-0 mt-2'
      />
      <DatePicker
        containerClass='w--100 mb-16 pb-0'
        legendClass='text--uppercase text--xxxsm'
        monthAsSelect={false}
        required
        legend={t("cec-screens-manual-input-dob", "Date of Birth")}
        month={month}
        day={day}
        year={year}
        onMonthChange={(newMonth) => {
          setMonth(newMonth);
        }}
        onDayChange={(newDay) => {
          setDay(newDay);
        }}
        onYearChange={(newYear) => {
          setYear(newYear);
        }}
      />
      <ButtonWrapper wrapperClass='flex--col-reverse mt-20'>
        <Button
          disabled={!isValid}
          label={t("cec-screens-manual-input-submit", "Submit")}
          onClick={async () => await onClick()}
          isLoading={isLoading}
        />
      </ButtonWrapper>
    </InputGroup>
  )
}