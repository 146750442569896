const DL_INPUT_LENGTH = 8;

const validateDate = (date) => {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
  }

export const validateDL = (obj) => {
    const { lastName, dlNumber, month, day, year } = obj;
    const isHasValue = lastName !== "" && dlNumber !== "";

    if (isHasValue) {
      const parsedYear = parseInt(year);
      const currentYear = new Date().getFullYear();

      if (isNaN(parsedYear) || parsedYear >= currentYear) {
        return false;
      }

      const dob = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
      return validateDate(dob) && dlNumber.length === DL_INPUT_LENGTH;
    }

    return false;
  }

export const isApplicationNch = (applicationType) => {
    return applicationType === "nch";
}

export const nchStatusSubtitles = {
    "processing": "Thank you for your payment. Your application is currently being processed.",
    "processed": "Your application has been processed successfully. You should receive your updated driver's license/identification card in 2-3 weeks.",
    "error": "Your documents and payment have been accepted by our online verification system, however we have encountered an issue with processing your application. Please visit a field office to complete your application.",
    "paymentNeeded": "Your documents have been approved. Complete your payment to finalize your application."
}

export const NCH_STATUS_ERROR = "error";
export const NCH_STATUS_PROCESSING = "processing";
export const NCH_STATUS_PROCESSED = "processed";
export const NCH_STATUS_PENDING = "paymentNeeded";

export const categoryIds = {
    "NCH_IDENTITY": 9,
    "NCH_SIGNATURE": 10,
    "NCH_NAME_CHANGE": 11,
    "NCH_NAME_TRACING": 12,
}

export const nchCategoryNameMap = {
    [categoryIds.NCH_IDENTITY]: "nch-screens-category9-legend",
    [categoryIds.NCH_SIGNATURE]: "nch-screens-category10-legend",
    [categoryIds.NCH_NAME_CHANGE]: "nch-screens-category11-legend",
    [categoryIds.NCH_NAME_TRACING]: "nch-screens-category12-legend"
};
