import { useCatalogs, useFileUpload, useApplication } from "@ca-dmv-radv/data";
import React, { useEffect } from "react";
import BirthCerificateForm from "./BirthCertificateForm";
import PassportForm from "./PassportForm";
import { isAbbyyFlow, isDocument, Documents } from '../helpers/constants';
import ExpirationDateForm from "./ExpirationDateForm";

export default function VerificationFields({ documentUpload }) {
  const { states, setShouldFetchStates, counties, setShouldFetchCounties } =
    useCatalogs();
  const {
    birthCertificateFormData,
    setBirthCertificateFormData,
  } = useFileUpload();
  const { documentTypes } = useApplication();
  useEffect(() => {
    if (isDocument(Documents.BIRTH_CERTIFICATE, documentTypes[documentUpload.catDocId])) {
      setShouldFetchStates(true);
      setShouldFetchCounties(true);
    }
  }, [documentUpload]);


  const handleBCFormDataChange = ({ key, value }) => {
    setBirthCertificateFormData({
      ...birthCertificateFormData,
      [key]: value,
    });
  };
  return (
    <>
      {isDocument(Documents.BIRTH_CERTIFICATE, documentTypes[documentUpload.catDocId]) && (
        <BirthCerificateForm
          states={states}
          counties={counties}
          changeHandler={handleBCFormDataChange}
          data={birthCertificateFormData}
        />
      )}

      {(isDocument(Documents.PASSPORT, documentTypes[documentUpload.catDocId]) || 
       isDocument(Documents.PASSPORT_CARD, documentTypes[documentUpload.catDocId])) && (
        <PassportForm
          documentUpload={documentUpload}
          isAbbyyFlow={isAbbyyFlow(documentUpload.ATD)}
        />
      )}

      {documentUpload.requiredExpirationDate && (
        <ExpirationDateForm isAbbyyFlow={isAbbyyFlow(documentUpload.ATD)} />
      )}
    </>
  );
}
