import { SMS_SUCCESS, useApplication, useModals, useSMS } from "@ca-dmv-radv/data";
import { useTranslation } from "@ca-dmv-radv/translation";
import React from "react";

import TakePhoto from "./TakePhoto";
import TakePhotoWaiting from "./TakePhotoWaiting";
import { isDocument, Documents } from "../helpers/constants";

export default function TakePhotoButton({
  documentUpload,
  selfieCall,
  setTriggerSMSModal,
}) {
  const { t } = useTranslation();
  const { documentTypes } = useApplication();
  const { sendingSMS, hasSentSMS } = useSMS();
  const { setTakingPhotoDocumentId } = useModals(null);

  if (sendingSMS !== SMS_SUCCESS && !hasSentSMS) {
    return (
      <TakePhoto
        document={selfieCall ? null : documentUpload}
        onClose={() => {
          setTakingPhotoDocumentId(null);
          if (selfieCall) setTriggerSMSModal(false);
        }}
        modalTitle={
          (isDocument(Documents.SIGNATURE, documentTypes[documentUpload.catDocId]) &&
            t(
              "nch-screens-category10-howTo",
              "How to Provide Your Signature"
            ))
          ||
          (selfieCall &&
            t(
              "screens-Dashboard-Modal-TakePhoto-modalSelfie",
              "Use your phone to take selfies"
            )) ||
          (!selfieCall &&
            t(
              "screens-Dashboard-Modal-TakePhoto-modalTitle",
              "Take a Photo {{documentName}}",
              {
                documentName: documentUpload.name,
              }
            ))
        }
      />
    );
  }
  return (
    <TakePhotoWaiting
      onClose={() => {
        setTakingPhotoDocumentId(null);
      }}
      document={documentUpload}
    />
  );
}
