import { useMounted } from "@ca-dmv-radv/utilities";
import { useCallback } from "react";
import { useTranslation } from "@ca-dmv-radv/translation";
import { getMDLNameChanges, getNameChanges, getApplicationData } from "../api";
import { useThrowFetchError } from "../error-context-provider";
import { parseReceivedNameChanges } from "./parseReceivedNameChanges";
import { useDocumentUploads } from '../document-uploads-context-provider';
import { useApplication } from "../application-context-provider";
import { APPLICATION_TYPE } from "../constants";

export default function useFetchNameChanges({ setNameChanges, category }) {
  const { application, applicationType } = useApplication();
  const { isMDLDocument } = useDocumentUploads();
  const mounted = useMounted();
  const throwFetchError = useThrowFetchError();
  const { t } = useTranslation;

  return useCallback(async () => {
    let success;
    let data;
    const application = await getApplicationData(APPLICATION_TYPE.DV)

    try {
      const nameChangeService = !isMDLDocument && getNameChanges;
      ({ data, success } = await nameChangeService());
      if (mounted.current) {
        setNameChanges(parseReceivedNameChanges(data, application, category));
      }
    } catch (error) {
      throwFetchError({
        error,
        message: t(
          "app-error-fetchingData-nameChange",
          "There was an error synchronizing your name change data."
        ),
      });
    }

    return success;
  }, [setNameChanges, throwFetchError, t]);
}
