import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMounted } from "@ca-dmv-radv/utilities";
import { useTranslation } from "@ca-dmv-radv/translation";
import { useApplication } from "../application-context-provider";
import { updateDocumentByCategory, updateIdentityDocument } from "../api";
import { DOCUMENT_CATEGORY_MDL_IDENTITY, FETCH_STATUS_IDLE, FETCH_STATUS_SENDING } from "../constants";
import { useThrowErrorSavingData } from "../error-context-provider";
import IdentityDocumentContext from "./IdentityDocumentContext";
import getIdentityStatus from "./getIdentityStatus";
import { useDocumentUploads } from "../document-uploads-context-provider";
import { useFileUpload } from "../file-upload-context-provider";

export function IdentityDocumentContextProvider({
  children,
  identityDocuments,
}) {
  const { t } = useTranslation();
  const { documentUploads, isMDLDocument } = useDocumentUploads();
  const { application, submitting, applicationType } = useApplication();
  const { resetFormData } = useFileUpload();
  const [selectedIdentityDocument, setSelectedIdentityDocument] = useState(
    identityDocuments?.find(
      ({ id }) => id === application?.documents?.identity?.catDocId
    )
  );
  const [updateIdentityDocumentStatus, setUpdateIdentityDocumentStatus] =
    useState(FETCH_STATUS_IDLE);
  const [relationshipDocuments, setRelationshipDocuments] = useState([]);
  const storedShowIdentityNameOptions = JSON.parse(localStorage.getItem('showIdentityNameOptions'));
  const [showIdentityNameOptions, setShowIdentityNameOptions] = useState(storedShowIdentityNameOptions || false);

  const previousSelectedIdentityDocumentId = useRef();
  const mounted = useMounted();
  const throwErrorSavingData = useThrowErrorSavingData();

  const postIdentityDocumentStatus = useCallback(async () => {
    if (!selectedIdentityDocument) {
      return true;
    }

    setUpdateIdentityDocumentStatus(FETCH_STATUS_SENDING);

    let success;

    const dto = { categorizedDocumentId: selectedIdentityDocument.id, }
    if (isMDLDocument) {
      dto["categoryId"] = DOCUMENT_CATEGORY_MDL_IDENTITY;
      dto["documentType"] = "identity";
    };

    try {
      const updateIdentityDocumentService = isMDLDocument
        ? updateDocumentByCategory
        : updateIdentityDocument;

      ({ success } = await updateIdentityDocumentService(dto , applicationType));

      if (!success) {
        throw new Error();
      }

      if (mounted.current) {
        setUpdateIdentityDocumentStatus(FETCH_STATUS_IDLE);
      }
    } catch (error) {
      throwErrorSavingData({
        error,
        message: t(
          "app-error-savingData-identityDocuments",
          "There was an error saving your identity document selection. Try again."
        ),
      });
    }

    return success;
  }, [selectedIdentityDocument, throwErrorSavingData, mounted]);

  const updateShowIdentityNameOptions = (value) => {
    setShowIdentityNameOptions(value);
    localStorage.setItem("showIdentityNameOptions", value);
  }

  // Reset form data when selectedIdentityDocument changes.
  useEffect(() => {
    resetFormData();
  }, [selectedIdentityDocument]);

  return (
    <IdentityDocumentContext.Provider
      value={useMemo(
        () => ({
          identityDocuments,
          identityStatus: getIdentityStatus(
            documentUploads,
            selectedIdentityDocument,
            application,
            submitting,
            applicationType
          ),
          postIdentityDocumentStatus,
          previousSelectedIdentityDocumentId:
            previousSelectedIdentityDocumentId.current,
          relationshipDocuments,
          selectedIdentityDocument,
          showIdentityNameOptions,
          setRelationshipDocuments,
          setSelectedIdentityDocument: (document) => {
            previousSelectedIdentityDocumentId.current =
              selectedIdentityDocument?.id;
            setSelectedIdentityDocument(document);
          },
          updateShowIdentityNameOptions,
          updateIdentityDocumentStatus,
        }),
        [
          application,
          documentUploads,
          identityDocuments,
          postIdentityDocumentStatus,
          relationshipDocuments,
          showIdentityNameOptions,
          selectedIdentityDocument,
          submitting,
          updateIdentityDocumentStatus,
        ]
      )}
    >
      {children}
    </IdentityDocumentContext.Provider>
  );
}

export { default as useIdentityDocument } from "./useIdentityDocument";
export { default as useOnIdentityDocumentChange } from "./useOnIdentityDocumentChange";
